import React from 'react'
import { Logo } from './logo'
import { useMetaData } from '../hooks'
import '../styles/splash.css'


export default () => {
  const { prismicHost: { data } } = useMetaData()

  return (
    <div className="splash">
      <Logo title={data.name} />
      <div className="background" />
    </div>
  )
}

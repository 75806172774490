import React from 'react'
import Contact from './contact'
import { Logo, ExpandedLogo } from './logo'
import { useMetaData } from '../hooks'
import '../styles/header.css'

export default ({ mobile, panelsVisible, setPanelsVisible }) => {
  const { prismicHost: { data } } = useMetaData()

  return (
    <nav className="header">
      {/*
        * Semantically this should be a header, since it is the most important
        * contextualizing content on the page. By placing it first in the DOM
        * we can use a skip to content link in the usual manner.
        */}
      <h1 onClick={() => panelsVisible && setPanelsVisible(!panelsVisible)}>
        <Logo className="desktop-hide" title={data.name} />
        <ExpandedLogo className="mobile-hide" title={data.name} />
      </h1>
      <ul>
        {data.primary_contacts.map(
          ({ contact_method: { document } }) => (
            <li key={document.id}>
              <Contact {...document} />
            </li>
          )
        )}
      </ul>
      <button onClick={() => setPanelsVisible(!panelsVisible)}>
        <span>
          {panelsVisible ? `×` : `i`}
        </span>
      </button>
    </nav>
  )
}

import React, { useState, useRef } from 'react'
import { useMetaData } from '../hooks'
import Contact from './contact'
import '../styles/information.css'

export default () => {
  const { prismicHost: { data } } = useMetaData()

  return [
    <div key="information-div" className="panel-content" dangerouslySetInnerHTML={{ __html: data.information.html.replace(/&lt;br \/&gt;/g,`<br />`) }} />,
    <ul key="information-ul">
      {data.secondary_contacts.map(
        ({ contact_method: { document } }) => (
          <li key={document.id}>
            <Contact {...document} icon={true} />
          </li>
        )
      )}
    </ul>
  ]
}

export const ContactPanel = () => {
  const { prismicHost: { data } } = useMetaData()

  return [
    <div key="contact-div" className="panel-content">
      <ul>
        <li>
          <h2>{data.name}</h2>
        </li>
        {data.primary_contacts.map(
          ({ contact_method: { document } }) => (
            <li key={document.id}>
              <span className="contact-title">{document.data && document.data.name}</span>
              <Contact {...document} />
            </li>
          )
        )}
      </ul>
    </div>
  ]
}

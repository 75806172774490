import React, { useState, useRef } from 'react'
import { throttle } from 'throttle-debounce'
import Contact from './contact'
import Region from './region'
import { useMetaData, useEventListener } from '../hooks'
import '../styles/recognitions.css'

export default () => {
  const { prismicHost: { data: { order } } } = useMetaData(),
        regions = order.filter(({ region }) => region.document)
                    .map(({ region: { document: region } }) => region)

  const element = useRef(null),
        [activeRegion, setActiveRegion] = useState(regions[0].data.name)

  // Watch scroll for to change region
  useEventListener('scroll', throttle(100, (e) => {
    const scrollTop = element.current && element.current.parentElement && element.current.parentElement.parentElement.scrollTop,
          // window.innerHeight reflects change in iOS Safari tap bar
          // wheras document.documentElement.clientHeight does not
          // Subtract 73 to accomodate footer
          clientCenter = (window.innerHeight - 73) / 2,
          allRegions = Array.from(document.querySelectorAll('.region')),
          centeredRegion = allRegions.find(p => {
            const regionTop = p.offsetTop - scrollTop,
                  regionTopAboveCenter = regionTop <= clientCenter,
                  regionBottom = regionTop + p.offsetHeight,
                  regionBottomBelowCenter = regionBottom >= clientCenter
            return regionTopAboveCenter && regionBottomBelowCenter
          }),
          centeredRegionName = centeredRegion
            ? centeredRegion.getAttribute(`data-name`)
            : regions[0].data.name

    // Don't need to scroll to region, user scrolled manually
    if(centeredRegionName !== activeRegion) setActiveRegion(centeredRegionName)
  }), {
    element: element.current && element.current.parentElement && element.current.parentElement.parentElement,
    passive: true
  })

  return (
    <div ref={element}>
      <h3 className="mobile-hide">{activeRegion}</h3>
      {regions
        ? regions.map(region => (
          <Region key={region.id} region={region} />
        )) : null
      }
    </div>
  )
}

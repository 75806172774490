import React from 'react'
import Collaborator from './collaborator'
import {
  siteName,
  siteAddress,
  siteType,
  siteAcreage
} from '../utils'
import '../styles/site.css'

export default ({ site }) => {
  const { id, data } = site,
        name = siteName(data),
        address = siteAddress(data),
        type = siteType(data),
        acreage = siteAcreage(data)

  return (
    <div className="site">
      <p className="name">{name}</p>
      <p className="details">
        {address}
        {address && (type || acreage) && <br />}
        {type && acreage
          ? `${type}, ${acreage}`
          : type
            ? type
            : acreage
              ? acreage
              : null
        }
      </p>
      <div className="collaborators">
        {data.collaborators.map(({ collaborator: { document } }) =>
          document
            ? <Collaborator key={document.id} collaborator={document} />
            : null
        )}
      </div>
    </div>
  )
}

import { graphql, useStaticQuery } from 'gatsby'

export default function useMetaData() {
  const data = useStaticQuery(graphql`
    query useMetaDataQuery {
      prismicHost {
        id
        data {
          name
          url
          primary_contacts {
            contact_method {
              document {
                ... on PrismicContactMethod {
                  id
                  data {
                    method
                    name
                  }
                }
              }
            }
          }
          seo_images {
            seo_image {
              alt
              copyright
              dimensions {
                height
                width
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 3840, quality: 60) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              url
            }
          }
          information {
            html
            raw
          }
          secondary_contacts {
            contact_method {
              document {
                ... on PrismicContactMethod {
                  id
                  data {
                    method
                    name
                  }
                }
              }
            }
          }
          order {
            region {
              document {
                ... on PrismicRegion {
                  id
                  data {
                    name
                    order {
                      site {
                        id
                        document {
                          ... on PrismicSite {
                            id
                            data {
                              acreage
                              address
                              neighborhood
                              collaborators {
                                collaborator {
                                  document {
                                    ... on PrismicCollaborator {
                                      id
                                      data {
                                        email
                                        name
                                        role {
                                          document {
                                            ... on PrismicRole {
                                              data {
                                                name
                                              }
                                            }
                                          }
                                        }
                                        url {
                                          url
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              images {
                                image {
                                  alt
                                  copyright
                                  dimensions {
                                    height
                                    width
                                  }
                                  localFile {
                                    childImageSharp {
                                      fluid(maxWidth: 3840, quality: 60, srcSetBreakpoints: [415, 830, 1440, 1920, 2880, 3840]) {
                                        ...GatsbyImageSharpFluid_withWebp
                                      }
                                    }
                                  }
                                  url
                                }
                              }
                              region {
                                document {
                                  ... on PrismicRegion {
                                    id
                                    data {
                                      name
                                    }
                                  }
                                }
                              }
                              type {
                                document {
                                  ... on PrismicType {
                                    data {
                                      name
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return data
}

import React, { useEffect, useRef, useState} from 'react'
import Img from 'gatsby-image'
import arrayShuffle from 'array-shuffle'
import { useAllData, useEventListener } from '../hooks'
import { siteCaption } from '../utils'
import '../styles/slideshow.css'

const generateSlides = (edges) => {
  let slides = [],
      urls = [],
      dupes = []
  edges.forEach(({ node }) => {
    if(!node || !node.data || !node.data.images || !node.data.images.length) return
    const caption = siteCaption(node.data)
    slides = slides.concat(
      node.data.images.map(({ image }) =>
        image.localFile
          ? Object.assign({}, {
            ...image,
            caption
          }) : null
      ).filter(slide =>
        slide !== null
      )
    )
  })

  // Remove duplicate urls so we don't encounter keying issues
  urls = slides.map(slide => slide.url)
  urls.map((url, i) =>
    urls.indexOf(url) !== i
      ? i
      : false
  ).filter(index =>
    index
  ).forEach(dupe => {
    slides.splice(dupe, 1)
  })

  return arrayShuffle(slides)
}

export default ({ landscape, mobile }) => {
  const { allPrismicSite: { edges } } = useAllData(),
        [slides, setSlides] = useState([]),
        [index, setIndex] = useState(0),
        [slide, setSlide] = useState(),
        interval = useRef(),
        wait = 6000

  useEffect(() => {
    // Generate slides in useEffect so they are randomized in the browser
    setSlides(generateSlides(edges))
  }, [])

  useEffect(() => {
    // If we receive slides for the first time, set initial slide
    if(!slide) setSlide(slides[index])

    // Clear existing interval
    if(interval.current) clearInterval(interval.current)

    // Increment index after wait
    interval.current = setInterval(() => {
      const newIndex = index + 1 < slides.length
        ? index + 1
        : 0
      setIndex(newIndex)
      setSlide(slides[newIndex])
    }, wait)
  }, [index, slides])

  const currentSlides = index < 1
    ? slides.slice(-1).concat(slides.slice(0, 2))
    : index + 3 < slides.length
      ? slides.slice(index - 1, index + 2)
      : slides.slice(index - 1).concat(slides.slice(0, 1))

  const nextSlide = () => {
    const newIndex = index + 1 < slides.length
      ? index + 1
      : 0
    setIndex(newIndex)
    setSlide(slides[newIndex])
  }

  const prevSlide = () => {
    const newIndex = index - 1 >= 0
      ? index - 1
      : slides.length - 1
    setIndex(newIndex)
    setSlide(slides[newIndex])
  }

  const handleClick = e => {
    if(e.clientX > document.documentElement.clientWidth / 2) nextSlide()
    else prevSlide()
  }

  useEventListener(`keyup`, (e) => {
    let newIndex

    switch(e.key) {
      case `ArrowRight`:
      case `ArrowDown`:
        newIndex = index + 1 < slides.length
          ? index + 1
          : 0
        break
      case `ArrowLeft`:
      case `ArrowUp`:
        newIndex = index - 1 >= 0
          ? index - 1
          : slides.length - 1
        break
      default:
        return
        break
    }

    setIndex(newIndex)
    setSlide(slides[newIndex])
  }, {
    passive: true
  })

  return (
      <div
        className="slideshow"
        onClick={handleClick}>
        <div className="carousel">
          {(mobile && !landscape
            ? slides
            : currentSlides
          ).map((slide, i) => (
            <Slide
              key={slide.localFile.childImageSharp.fluid.src.split(`/`)[2]}
              slide={slide}
              active={i === 1}
            />
          ))}
        </div>
        {(!mobile && (
          <div className="caption">
            {slide && slide.caption}
          </div>
        ))}
      </div>
    )
}

const Slide = ({ slide, active }) => {
  const fluid = slide.localFile.childImageSharp.fluid,
        classes = `slide ${active ? 'active' : ''}`

  return (
  <div className={classes}>
      <Img fluid={fluid} placeholderClassName="placeholder" />
    </div>
  )
}

import { useEffect, useState } from 'react'

export default function useSupportsPassive() {
  const [supportsPassive, setSupportsPassive] = useState(false)

  useEffect(() => {
    try {
      var opts = Object.defineProperty({}, 'passive', {
        get: function() {
          return setSupportsPassive(true)
        }
      })
      window.addEventListener("testPassive", null, opts)
      window.removeEventListener("testPassive", null, opts)
    } catch (e) {}
  }, [])

  return supportsPassive
    ? { passive: true }
    : false
}

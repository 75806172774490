import { graphql, useStaticQuery } from 'gatsby'

export default function useAllData() {
  const data = useStaticQuery(graphql`
    query useAllDataQuery {
      allPrismicSite {
        edges {
          node {
            id
            data {
              acreage
              address
              neighborhood
              collaborators {
                collaborator {
                  document {
                    ... on PrismicCollaborator {
                      id
                      data {
                        email
                        name
                        role {
                          document {
                            ... on PrismicRole {
                              data {
                                name
                              }
                            }
                          }
                        }
                        url {
                          url
                        }
                      }
                    }
                  }
                }
              }
              images {
                image {
                  alt
                  copyright
                  dimensions {
                    height
                    width
                  }
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 3840, quality: 60, srcSetBreakpoints: [415, 830, 1440, 1920, 2880, 3840]) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  url
                }
              }
              region {
                document {
                  ... on PrismicRegion {
                    id
                    data {
                      name
                    }
                  }
                }
              }
              type {
                document {
                  ... on PrismicType {
                    data {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
      prismicHost {
        id
        data {
          name
          information {
            html
          }
          primary_contacts {
            contact_method {
              document {
                ... on PrismicContactMethod {
                  id
                  data {
                    method
                    name
                  }
                }
              }
            }
          }
          secondary_contacts {
            contact_method {
              document {
                ... on PrismicContactMethod {
                  id
                  data {
                    method
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return data
}

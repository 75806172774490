import React from 'react'

export const Logo = ({ title, className }) => {
  return (
    <svg className={`logo ${className ? className : ``}`} viewBox="0 0 495.71 78.62">
      {title
        ? <title>{title}</title>
        : null
      }
      <g>
        <path d="M11,76.68A47.79,47.79,0,0,1,0,72.25L.65,50.87H2.27Q4.21,62.53,10.1,69.5t15.93,7q7.66,0,12.26-4.37a15.16,15.16,0,0,0,4.59-11.5,12.49,12.49,0,0,0-4.05-9.72q-4-3.67-12.8-7.24L21.6,41.8q-10.47-4.32-15.39-9A16,16,0,0,1,1.3,20.63,18.3,18.3,0,0,1,4.54,10a21.92,21.92,0,0,1,8.69-7.34A26.39,26.39,0,0,1,25,0,47.65,47.65,0,0,1,36.18,1.19a78.52,78.52,0,0,1,9.61,3l-.43,18.58H43.74Q41.58,12.32,36.88,7.24T24.19,2.16q-6.92,0-11.12,3.94A13,13,0,0,0,8.86,16a11.69,11.69,0,0,0,3.72,9q3.74,3.45,12.8,7.13l4.43,1.83q11,4.65,15.93,9.34t4.91,12.59a21.45,21.45,0,0,1-3.24,11.5,22.64,22.64,0,0,1-9.12,8.26,28.86,28.86,0,0,1-13.34,3A50.28,50.28,0,0,1,11,76.68Z"/>
        <path d="M59.18,75.71l2-.33A13.6,13.6,0,0,0,66,73.6c1.12-.75,1.67-2.14,1.67-4.16V9.18A5.21,5.21,0,0,0,66,4.81a9.44,9.44,0,0,0-4.86-1.68l-2-.21V1.51h27V2.92l-1.94.21a9.37,9.37,0,0,0-4.86,1.68,5.22,5.22,0,0,0-1.73,4.37V69.44c0,2,.56,3.41,1.67,4.16a13.66,13.66,0,0,0,4.92,1.78l1.94.33v1.4h-27Z"/>
        <path d="M165.88,1.51V2.92l-1.72.21a9.24,9.24,0,0,0-5.35,2.22q-1.89,1.77-1.89,6V77.65h-1.19l-49-67.07v56.7q0,4.22,1.89,6a8.49,8.49,0,0,0,5.35,2.16l1.83.22v1.4H95v-1.4l1.72-.22a9.17,9.17,0,0,0,5.35-2.21q1.89-1.78,1.89-6V9.18a5.22,5.22,0,0,0-1.73-4.37,9.37,9.37,0,0,0-4.86-1.68l-1.94-.21V1.51h17l41.8,57.13V11.34c0-2.81-.63-4.82-1.89-6A8.6,8.6,0,0,0,147,3.13l-1.83-.21V1.51Z"/>
        <path d="M191.16,73.71a34.7,34.7,0,0,1-12.8-13.82,43,43,0,0,1-4.7-20.36A42.65,42.65,0,0,1,178.47,19,35.56,35.56,0,0,1,191.59,5,34.8,34.8,0,0,1,210,0a56.66,56.66,0,0,1,24.19,5.18V23.54h-1.62q-2.91-11.88-8.69-16.63a21.47,21.47,0,0,0-14.1-4.75A20.8,20.8,0,0,0,196.5,6.53,27,27,0,0,0,188,18.85a52.76,52.76,0,0,0-2.92,18.3,52.34,52.34,0,0,0,3.08,18.47,29,29,0,0,0,9.12,13,22.86,22.86,0,0,0,14.58,4.75,24.41,24.41,0,0,0,13.5-3.67q5.73-3.68,10.48-12l1.51.87q-8.64,20.09-28.08,20.08A34.25,34.25,0,0,1,191.16,73.71Z"/>
        <path d="M244.94,75.71l1.94-.33a13.66,13.66,0,0,0,4.92-1.78c1.11-.75,1.67-2.14,1.67-4.16V9.18a5.22,5.22,0,0,0-1.73-4.37,9.37,9.37,0,0,0-4.86-1.68l-1.94-.21V1.51h27V2.92L270,3.13a9.37,9.37,0,0,0-4.86,1.68,5.19,5.19,0,0,0-1.73,4.37V69.77c0,2,.57,3.36,1.73,4a10.68,10.68,0,0,0,5.29,1h5.29q7.35,0,11.83-2.1a16.73,16.73,0,0,0,7.18-6.48,41.74,41.74,0,0,0,4.53-12h1.41l-1.84,22.89H244.94Z"/>
        <path d="M381.78,75.71v1.4H353.91v-1.4l1.95-.22a11.17,11.17,0,0,0,4.91-1.4A3.5,3.5,0,0,0,362.45,71a10.74,10.74,0,0,0-.87-3.57l-5.83-14.9H326.91l-5.72,14.69a9.71,9.71,0,0,0-.86,3.78A3.49,3.49,0,0,0,322,74.14a11.42,11.42,0,0,0,5,1.35l1.95.22v1.4H308v-1.4l1.62-.22a8.92,8.92,0,0,0,5-2.32,17.47,17.47,0,0,0,3.72-6.32L344.63.76h1.08l26.13,66.09a19.14,19.14,0,0,0,3.57,6.32,8,8,0,0,0,4.75,2.32ZM354.89,50.22l-13.5-34.67L327.78,50.22Z"/>
        <path d="M390.74,75.71l1.94-.33a13.66,13.66,0,0,0,4.92-1.78c1.11-.75,1.67-2.14,1.67-4.16V9.18a5.22,5.22,0,0,0-1.73-4.37,9.37,9.37,0,0,0-4.86-1.68l-1.94-.21V1.51h27V2.92l-1.94.21a9.37,9.37,0,0,0-4.86,1.68,5.19,5.19,0,0,0-1.73,4.37V69.44c0,2,.55,3.41,1.67,4.16a13.66,13.66,0,0,0,4.92,1.78l1.94.33v1.4h-27Z"/>
        <path d="M495.71,75.71v1.4h-16c-.72-.93-1.26-1.62-1.62-2q-5.61-7.35-7.34-9.94L457.91,46.44a18.52,18.52,0,0,0-3.56-4.27,5.88,5.88,0,0,0-3.46-.91h-5V69.44c0,2,.57,3.41,1.72,4.16a15.37,15.37,0,0,0,5.73,1.78l2.05.33v1.4h-28v-1.4l1.94-.33a13.66,13.66,0,0,0,4.92-1.78c1.11-.75,1.67-2.14,1.67-4.16V9.18a5.22,5.22,0,0,0-1.73-4.37,9.37,9.37,0,0,0-4.86-1.68l-1.94-.21V1.51h30.35a38,38,0,0,1,12.25,2,21.14,21.14,0,0,1,9.51,6.26,16.28,16.28,0,0,1,3.72,11q0,8-5.23,12.9a26.74,26.74,0,0,1-13.45,6.54l14.8,21a76.92,76.92,0,0,0,8.42,10.58,13,13,0,0,0,6.26,3.67ZM455.65,39q17.7,0,17.71-17.17,0-9.72-5.19-13.88t-13.6-4.16h-8.64V39Z"/>
      </g>
    </svg>
  )
}

export const ExpandedLogo = ({ title, className }) => {
  return (
    <svg className={`expanded-logo ${className ? className : ``}`} viewBox="0 0 337.56 58.24">
      {title
        ? <title>{title}</title>
        : null
      }
      <g>
        <path d="M1.53,14.37H2c.73,4.39,2.93,7.23,6.71,7.23,2.9,0,4.76-1.8,4.76-4.48,0-2.29-1.46-3.45-4.76-4.79l-1.25-.52c-3.93-1.62-5.73-3.17-5.73-6C1.72,2.44,4.83,0,8.4,0a15,15,0,0,1,5.89,1.19l-.12,5.25h-.46C12.89,2.51,11.21.61,8.19.61,5.6.61,3.86,2.29,3.86,4.51A3.27,3.27,0,0,0,4.9,7a12.87,12.87,0,0,0,3.63,2l1.25.52a15.09,15.09,0,0,1,4.48,2.65,4.55,4.55,0,0,1,1.4,3.54c0,3.57-3,6.44-7.26,6.44a14.87,14.87,0,0,1-7-1.8Z"/>
        <path d="M18.76,21.39l.55-.09c1.22-.24,1.86-.55,1.86-1.68V2.6c0-1.13-.67-1.58-1.86-1.71L18.76.83V.43h7.62v.4l-.55.06C24.64,1,24,1.47,24,2.6v17c0,1.13.64,1.43,1.86,1.68l.55.09v.4H18.76Z"/>
        <path d="M49,.44v.4L48.52.9a2,2,0,0,0-2,2.32V22h-.34L32.29,3V19a2,2,0,0,0,2,2.32l.52.06v.4H29v-.4l.49-.06a2,2,0,0,0,2-2.32V2.61c0-1.13-.67-1.58-1.86-1.71L29.11.84V.44H33.9L45.7,16.57V3.21a2,2,0,0,0-2-2.32L43.14.83V.43H49Z"/>
        <path d="M61.91,0a16,16,0,0,1,6.83,1.46V6.65h-.46c-1.1-4.48-3.29-6-6.44-6-4.36,0-7,4-7,9.88s2.72,10.22,7.56,10.22c2.93,0,5-1.28,6.77-4.42l.43.24q-2.43,5.67-7.93,5.67c-5.61.09-10.16-4.61-10.06-11C51.54,4.67,56.27,0,61.91,0Z"/>
        <path d="M72.55,21.39l.55-.09c1.22-.24,1.86-.55,1.86-1.68V2.6C75,1.47,74.29,1,73.1.89L72.55.83V.43h7.62v.4l-.55.06c-1.19.12-1.86.58-1.86,1.71V19.71c0,1.13.64,1.43,2,1.43h1.49c4.18-.09,5.55-1.52,6.65-5.83h.4l-.52,6.47H72.54v-.39Z"/>
        <path d="M111.13,21.39v.4h-7.87v-.4l.55-.06c1.22-.12,1.86-.52,1.86-1.28a3.45,3.45,0,0,0-.24-1l-1.65-4.21H95.64L94,19a3,3,0,0,0-.24,1.07c0,.82.61,1.16,1.86,1.28l.55.06v.4H90.3v-.4l.46-.06c1.13-.15,1.83-.76,2.47-2.44L100.64.22h.3l7.38,18.67c.64,1.68,1.25,2.29,2.35,2.44Zm-7.59-7.2L99.73,4.4l-3.84,9.79Z"/>
        <path d="M113.33,21.39l.55-.09c1.22-.24,1.86-.55,1.86-1.68V2.6c0-1.13-.67-1.58-1.86-1.71l-.55-.06V.43H121v.4l-.55.06c-1.19.12-1.86.58-1.86,1.71v17c0,1.13.64,1.43,1.86,1.68l.55.09v.4h-7.62v-.4Z"/>
        <path d="M143.15,21.39v.4h-4.51l-.46-.58c-1.07-1.37-1.74-2.32-2.07-2.81l-3.63-5.28c-.85-1.25-1.16-1.46-2-1.46h-1.4v8c0,1.13.61,1.43,2.1,1.68l.58.09v.4h-7.9v-.4l.55-.09c1.22-.24,1.86-.55,1.86-1.68V2.6c0-1.13-.67-1.58-1.86-1.71l-.55-.06V.43h8.57c3.6,0,7.2,1.58,7.2,5.43,0,3-2.19,4.88-5.28,5.49l4.18,5.92a24.43,24.43,0,0,0,2.38,3,3.67,3.67,0,0,0,1.77,1ZM131.84,11q5,0,5-4.85c0-3.66-2.13-5.09-5.31-5.09h-2.44V11Z"/>
        <path d="M170.29,21.39v.4h-7.87v-.4l.55-.06c1.22-.12,1.86-.52,1.86-1.28a3.45,3.45,0,0,0-.24-1l-1.65-4.21H154.8L153.18,19a3,3,0,0,0-.24,1.07c0,.82.61,1.16,1.86,1.28l.55.06v.4h-5.89v-.4l.46-.06c1.13-.15,1.83-.76,2.47-2.44L159.8.22h.3l7.38,18.67c.64,1.68,1.25,2.29,2.35,2.44Zm-7.6-7.2L158.88,4.4,155,14.19Z"/>
        <path d="M172.91,14.37h.46c.73,4.39,2.93,7.23,6.71,7.23,2.9,0,4.76-1.8,4.76-4.48,0-2.29-1.46-3.45-4.76-4.79l-1.25-.52c-3.93-1.62-5.73-3.17-5.73-6,0-3.39,3.11-5.83,6.68-5.83a15,15,0,0,1,5.89,1.19l-.12,5.25h-.46c-.82-3.93-2.5-5.83-5.52-5.83-2.59,0-4.33,1.68-4.33,3.9a3.27,3.27,0,0,0,1,2.53,12.87,12.87,0,0,0,3.63,2l1.25.52a15.09,15.09,0,0,1,4.48,2.65,4.55,4.55,0,0,1,1.4,3.54c0,3.57-3,6.44-7.26,6.44a14.87,14.87,0,0,1-7-1.8Z"/>
        <path d="M190.87,14.37h.46c.73,4.39,2.93,7.23,6.71,7.23,2.9,0,4.76-1.8,4.76-4.48,0-2.29-1.46-3.45-4.76-4.79l-1.25-.52c-3.93-1.62-5.73-3.17-5.73-6,0-3.39,3.11-5.83,6.68-5.83a15,15,0,0,1,5.89,1.19l-.12,5.25h-.46c-.82-3.93-2.5-5.83-5.52-5.83-2.59,0-4.33,1.68-4.33,3.9a3.27,3.27,0,0,0,1,2.53,12.87,12.87,0,0,0,3.63,2l1.25.52a15.09,15.09,0,0,1,4.48,2.65,4.55,4.55,0,0,1,1.4,3.54c0,3.57-3,6.44-7.26,6.44a14.87,14.87,0,0,1-7-1.8Z"/>
        <path d="M218.77,0c5.58-.09,10.22,4.61,10.13,11,.09,6.5-4.54,11.25-10.13,11.16-5.58.09-10.22-4.61-10.12-11C208.56,4.67,213.19,0,218.77,0Zm-.21.61c-3.93,0-6.68,4.06-6.68,9.88,0,6,2.72,11.19,7.11,11.1,3.93,0,6.68-4.06,6.68-9.88C225.73,5.62,223,.53,218.56.62Z"/>
        <path d="M243.05,0a16,16,0,0,1,6.83,1.46V6.65h-.46c-1.1-4.48-3.29-6-6.44-6-4.36,0-6.95,4-6.95,9.88s2.72,10.22,7.56,10.22c2.93,0,5-1.28,6.77-4.42l.43.24q-2.43,5.67-7.93,5.67c-5.61.09-10.16-4.61-10.06-11C232.68,4.67,237.41,0,243.05,0Z"/>
        <path d="M253.75,21.39l.55-.09c1.22-.24,1.86-.55,1.86-1.68V2.6c0-1.13-.67-1.58-1.86-1.71l-.55-.06V.43h7.62v.4l-.55.06C259.63,1,259,1.47,259,2.6v17c0,1.13.64,1.43,1.86,1.68l.55.09v.4h-7.62Z"/>
        <path d="M284.4,21.39v.4h-7.87v-.4l.55-.06c1.22-.12,1.86-.52,1.86-1.28a3.45,3.45,0,0,0-.24-1l-1.65-4.21h-8.14L267.29,19a3,3,0,0,0-.24,1.07c0,.82.61,1.16,1.86,1.28l.55.06v.4h-5.89v-.4l.46-.06c1.13-.15,1.83-.76,2.47-2.44L273.91.22h.3l7.38,18.67c.64,1.68,1.25,2.29,2.35,2.44Zm-7.59-7.2L273,4.4l-3.84,9.79Z"/>
        <path d="M288.12,21.39l.55-.09c1.22-.24,1.86-.55,1.86-1.68V1.08H289.1a5.57,5.57,0,0,0-3.81,1.1,6.68,6.68,0,0,0-1.77,3.69h-.4l.4-5.43h16.83l.4,5.43h-.4a6.61,6.61,0,0,0-1.77-3.69,5.44,5.44,0,0,0-3.78-1.1h-1.46V19.62c0,1.13.64,1.43,1.86,1.68l.55.09v.4h-7.62v-.4Z"/>
        <path d="M319.59,15.78l-.43,6H303.55v-.4l.55-.09c1.22-.24,1.86-.55,1.86-1.68V2.6c0-1.13-.67-1.58-1.86-1.71l-.55-.06V.43h14.94V5.52h-.4c-.67-3.2-1.83-4.45-5.18-4.45h-4.15v9.42h2.38c2.2,0,3.17-.79,3.66-2.74h.37v6.4h-.37c-.52-2-1.49-3-3.66-3h-2.38v8.54c0,1.19.64,1.46,2,1.46h1.95c4.21-.09,5.28-1,6.5-5.37h.4Z"/>
        <path d="M323.43,14.37h.46c.73,4.39,2.93,7.23,6.71,7.23,2.9,0,4.76-1.8,4.76-4.48,0-2.29-1.46-3.45-4.76-4.79l-1.25-.52c-3.93-1.62-5.73-3.17-5.73-6,0-3.39,3.11-5.83,6.68-5.83a15,15,0,0,1,5.89,1.19l-.12,5.25h-.46c-.82-3.93-2.5-5.83-5.52-5.83-2.59,0-4.33,1.68-4.33,3.9a3.27,3.27,0,0,0,1,2.53,12.87,12.87,0,0,0,3.63,2l1.25.52a15.09,15.09,0,0,1,4.48,2.65,4.55,4.55,0,0,1,1.4,3.54c0,3.57-3,6.44-7.26,6.44a14.87,14.87,0,0,1-7-1.8Z"/>
        <path d="M20.83,57.43v.4H13v-.4l.55-.06c1.22-.12,1.86-.52,1.86-1.28a3.45,3.45,0,0,0-.24-1l-1.65-4.21H5.34L3.72,55a3,3,0,0,0-.24,1.07c0,.82.61,1.16,1.86,1.28l.55.06v.4H0v-.4l.46-.06c1.13-.15,1.83-.76,2.47-2.44l7.41-18.67h.3L18,54.93c.64,1.68,1.25,2.29,2.35,2.44Zm-7.59-7.2L9.43,40.44,5.59,50.23Z"/>
        <path d="M42.24,57.43v.4H37.73l-.46-.58c-1.07-1.37-1.74-2.32-2.07-2.81l-3.63-5.28c-.85-1.25-1.16-1.46-2-1.46h-1.4v8c0,1.13.61,1.43,2.1,1.68l.58.09v.4H23v-.4l.55-.09c1.22-.24,1.86-.55,1.86-1.68v-17c0-1.13-.67-1.58-1.86-1.71L23,36.87v-.4h8.57c3.6,0,7.2,1.58,7.2,5.43,0,3-2.19,4.88-5.28,5.49l4.18,5.92a24.43,24.43,0,0,0,2.38,3,3.67,3.67,0,0,0,1.77,1ZM30.93,47.06q5,0,5-4.85c0-3.66-2.13-5.09-5.31-5.09H28.18v9.94Z"/>
        <path d="M53.37,36.05a16,16,0,0,1,6.83,1.46v5.18h-.46c-1.1-4.48-3.29-6-6.44-6-4.36,0-6.95,4-6.95,9.88s2.72,10.22,7.56,10.22c2.93,0,5-1.28,6.77-4.42l.43.24q-2.43,5.67-7.93,5.67c-5.61.09-10.16-4.61-10.06-11C43,40.72,47.73,36,53.37,36.05Z"/>
        <path d="M82.25,55.66c0,1.13.64,1.43,1.86,1.68l.55.09v.4H77v-.4l.55-.09c1.22-.24,1.86-.55,1.86-1.68V47.09H69.23v8.57c0,1.13.64,1.43,1.86,1.68l.55.09v.4H64v-.4l.55-.09c1.22-.24,1.86-.55,1.86-1.68v-17c0-1.13-.67-1.58-1.86-1.71L64,36.87v-.4h7.62v.4l-.55.06c-1.19.12-1.86.58-1.86,1.71v7.81H79.45V38.64c0-1.13-.67-1.58-1.86-1.71L77,36.87v-.4h7.62v.4l-.55.06c-1.19.12-1.86.58-1.86,1.71Z"/>
        <path d="M87.59,57.43l.55-.09c1.22-.24,1.86-.55,1.86-1.68v-17c0-1.13-.67-1.58-1.86-1.71l-.55-.06v-.4h7.62v.4l-.55.06c-1.19.12-1.86.58-1.86,1.71v17c0,1.13.64,1.43,1.86,1.68l.55.09v.4H87.59Z"/>
        <path d="M103.08,57.43l.55-.09c1.22-.24,1.86-.55,1.86-1.68V37.12h-1.43a5.57,5.57,0,0,0-3.81,1.1,6.68,6.68,0,0,0-1.77,3.69h-.4l.4-5.43h16.83l.4,5.43h-.4a6.61,6.61,0,0,0-1.77-3.69,5.44,5.44,0,0,0-3.78-1.1H108.3V55.66c0,1.13.64,1.43,1.86,1.68l.55.09v.4h-7.62v-.4Z"/>
        <path d="M134.55,51.82l-.43,6H118.51v-.4l.55-.09c1.22-.24,1.86-.55,1.86-1.68v-17c0-1.13-.67-1.58-1.86-1.71l-.55-.06v-.4h14.94v5.09h-.4c-.67-3.2-1.83-4.45-5.18-4.45h-4.15v9.42h2.38c2.2,0,3.17-.79,3.66-2.74h.37v6.4h-.37c-.52-2-1.49-3-3.66-3h-2.38v8.54c0,1.19.64,1.46,2,1.46h2c4.21-.09,5.28-1,6.5-5.37h.4Z"/>
        <path d="M147.91,36.05a16,16,0,0,1,6.83,1.46v5.18h-.46c-1.1-4.48-3.29-6-6.44-6-4.36,0-7,4-7,9.88s2.72,10.22,7.56,10.22c2.93,0,5-1.28,6.77-4.42l.43.24q-2.43,5.67-7.93,5.67c-5.61.09-10.16-4.61-10.06-11C137.54,40.72,142.27,36,147.91,36.05Z"/>
        <path d="M163.49,57.43l.55-.09c1.22-.24,1.86-.55,1.86-1.68V37.12h-1.43a5.57,5.57,0,0,0-3.81,1.1,6.68,6.68,0,0,0-1.77,3.69h-.4l.4-5.43h16.83l.4,5.43h-.4A6.61,6.61,0,0,0,174,38.22a5.44,5.44,0,0,0-3.78-1.1h-1.46V55.66c0,1.13.64,1.43,1.86,1.68l.55.09v.4H163.5v-.4Z"/>
        <path d="M179.44,50.41h.46c.73,4.39,2.93,7.23,6.71,7.23,2.9,0,4.76-1.8,4.76-4.48,0-2.29-1.46-3.45-4.76-4.79l-1.25-.52c-3.93-1.62-5.73-3.17-5.73-6,0-3.39,3.11-5.83,6.68-5.83a15,15,0,0,1,5.89,1.19l-.12,5.25h-.46c-.82-3.93-2.5-5.83-5.52-5.83-2.59,0-4.33,1.68-4.33,3.9a3.27,3.27,0,0,0,1,2.53,12.87,12.87,0,0,0,3.63,2l1.25.52a15.09,15.09,0,0,1,4.48,2.65,4.55,4.55,0,0,1,1.4,3.54c0,3.57-3,6.44-7.26,6.44a14.87,14.87,0,0,1-7-1.8Z"/>
        <path d="M203.08,57.43l.55-.09c1.22-.24,1.86-.55,1.86-1.68v-17c0-1.13-.67-1.58-1.86-1.71l-.55-.06v-.4h7.62v.4l-.55.06c-1.19.12-1.86.58-1.86,1.71v17c0,1.13.64,1.43,1.86,1.68l.55.09v.4h-7.62Z"/>
        <path d="M233.33,36.48v.4l-.49.06a2,2,0,0,0-2,2.32V58h-.34L216.61,39.05v16a2,2,0,0,0,2,2.32l.52.06v.4h-5.86v-.4l.49-.06a2,2,0,0,0,2-2.32V38.65c0-1.13-.67-1.58-1.86-1.71l-.55-.06v-.4h4.79L230,52.61V39.25a2,2,0,0,0-2-2.32l-.52-.06v-.4h5.87Z"/>
        <path d="M246.23,36.05a16,16,0,0,1,6.83,1.46v5.18h-.46c-1.1-4.48-3.29-6-6.44-6-4.36,0-6.95,4-6.95,9.88s2.72,10.22,7.56,10.22c2.93,0,5-1.28,6.77-4.42l.43.24q-2.43,5.67-7.93,5.67c-5.61.09-10.16-4.61-10.06-11C235.86,40.72,240.59,36,246.23,36.05Z"/>
        <path d="M259.07,54.59a1.74,1.74,0,1,1-1.77,1.74A1.79,1.79,0,0,1,259.07,54.59Z"/>
      </g>
    </svg>
  )
}

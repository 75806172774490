import AtlasGroteskRegularWoff from './AtlasGrotesk-Regular-Web.woff'
import AtlasGroteskRegularWoff2 from './AtlasGrotesk-Regular-Web.woff2'
import AtlasGroteskMediumWoff from './AtlasGrotesk-Medium-Web.woff'
import AtlasGroteskMediumWoff2 from './AtlasGrotesk-Medium-Web.woff2'

import IvarTextRegularWoff from './IvarText-Regular.woff'
import IvarTextRegularWoff2 from './IvarText-Regular.woff2'
import IvarTextSemiBoldWoff from './IvarText-SemiBold.woff'
import IvarTextSemiBoldWoff2 from './IvarText-SemiBold.woff2'

export default {
  AtlasGrotesk: {
    400: {
      normal: {
        woff: AtlasGroteskRegularWoff,
        woff2: AtlasGroteskRegularWoff2,
      },
    },
    500: {
      normal: {
        woff: AtlasGroteskMediumWoff,
        woff2: AtlasGroteskMediumWoff2,
      },
    },
  },
  IvarText: {
    400: {
      normal: {
        woff: IvarTextRegularWoff,
        woff2: IvarTextRegularWoff2,
      },
    },
    500: {
      normal: {
        woff: IvarTextSemiBoldWoff,
        woff2: IvarTextSemiBoldWoff2,
      }
    },
  },
}

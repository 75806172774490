import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { throttle } from 'throttle-debounce'
import { useEventListener, usePrevious, useSupportsPassive } from '../hooks'
import '../styles/panel.css'

// https://github.com/iamdustan/smoothscroll/issues/130#issuecomment-542787718
const scrollIntoViewPromise = (parent, element, supportsPassive, mobile, wait = 2500) => {
  let scrollListener, timeout

  return new Promise((resolve, reject) => {
    scrollListener = throttle(100, e => e.target.scrollTop === element.offsetTop && resolve())
    timeout = setTimeout(() => reject && reject(), wait)
    parent.addEventListener(`scroll`, scrollListener, supportsPassive ? { passive : true } : undefined)
    parent.scroll({ top: element.offsetTop - (mobile ? 93 : 0), left: 0, behavior: `smooth` })
  }).then(
    () => clearTimeout(timeout)
  ).catch(
    () => false
  ).finally(
    () => parent.removeEventListener(`scroll`, scrollListener, supportsPassive ? { passive : true } : undefined)
  )
}

export const ScrollingPanel = ({ children, className, activePanel, setActivePanel, panelsVisible, mobile, landscape, layout, ...rest }) => {

  const element = useRef(null),
        // Track whether we are scrolling algorithmically
        // so user scroll handler doesn't prevent success
        scrolling = useRef(false),
        // Track whether actions require algorithmic scroll
        // so we do not delay user scroll handling when needed
        scrollTo = useRef(true),
        // Helper functions for panel setting/scrolling
        scrollToPanel = (panel) => {
          scrollTo.current = true
          setActivePanel(panel)
        },
        setPanel = (panel) => {
          scrollTo.current = false
          setActivePanel(panel)
        },
        // Track children
        previousChildren = usePrevious(children),
        // Render hack
        // "Two pass rendering"
        // https://github.com/gatsbyjs/gatsby/issues/17914
        [isClient, setIsClient] = useState(false),
        supportsPassive = useSupportsPassive()

  useEffect(() => {
    setIsClient(true)
  }, [])

  // Polyfill smoothscroll in browser
  useEffect(() => {
    import('smoothscroll-polyfill')
      .then(smoothscroll => smoothscroll.polyfill())
  }, [])

  // Switch to first panel when panels are hidden
  useEffect(() => {
    if(!panelsVisible) {
      const firstPanel = children[0].props.title.toLowerCase()
      if(firstPanel !== activePanel) scrollToPanel(firstPanel)
    }
  }, [panelsVisible])

  // Switch to current or first panel when children change
  useEffect(() => {
    // If we don't have new and prior children, forget it
    if(!children || !previousChildren) return
    // If their lengths are the same, forget it
    if(children.length === previousChildren.length) return

    // But if their lengths differ scroll to the first panel
    const firstPanel = children[0].props.title.toLowerCase()
    if(firstPanel !== activePanel) scrollToPanel(firstPanel)
  }, [children])

  // Scroll to active panel when requested via ref
  useEffect(() => {
    if(!scrollTo.current) return

    scrolling.current = true

    scrollIntoViewPromise(
      element.current,
      document.getElementById(`panel-${activePanel}`),
      supportsPassive,
      mobile
    ).finally(() =>
      scrolling.current = false
    )
  }, [activePanel])

  // Watch scroll for intention to change panels
  useEventListener('scroll', throttle(100, (e) => {
    // Don't interrupt algorithmic scrolling
    if(scrolling.current) return

    const scrollTop = element.current.scrollTop,
          // window.innerHeight reflects change in iOS Safari tap bar
          // wheras document.documentElement.clientHeight does not
          // Subtract 38 to accomodate mobile footer
          clientCenter = (window.innerHeight - (mobile ? -73 : 73)) / 2,
          allPanels = Array.from(document.querySelectorAll('.panel')),
          centeredPanel = allPanels.find(p => {
            const panelTop = p.offsetTop - scrollTop,
                  panelTopAboveCenter = panelTop <= clientCenter,
                  panelBottom = panelTop + p.offsetHeight,
                  panelBottomBelowCenter = panelBottom >= clientCenter
            return panelTopAboveCenter && panelBottomBelowCenter
          }),
          centeredPanelId = centeredPanel.id.replace(`panel-`, ``)

    // Don't need to scroll to panel, user scrolled manually
    if(centeredPanelId !== activePanel) setPanel(centeredPanelId)
  }), {
    element: element.current,
    passive: true
  })

  const panels = children.map(
    (child, i) => {
      const title = child.props.title.toLowerCase()
      return (
        <Panel
          key={title}
          title={title}>
          {child}
        </Panel>
      )
    }
  )

  console.log(layout)

  return (
    <div ref={element} key={isClient} className={className ? className : `scrolling-panel`} data-active={activePanel} {...rest}>
      {layout && createPortal((
        <nav className="panel-nav">
          {children.map(child => {
            const title = child.props.title.toLowerCase()
            return (
              <button
                key={title}
                className={`panel-link ${activePanel === title ? 'active' : ''}`}
                onClick={() => scrollToPanel(title)}>
                <span className="panel-link-text">
                  {child.props.title}
                </span>
              </button>
            )
        })}
        </nav>
      ), layout)}
      {children.map((child, i) => {
        const title = child.props.title.toLowerCase()
        return (
          <Panel
            key={title}
            title={title}>
            {child}
          </Panel>
        )
      })}
    </div>
  )
}

const Panel = ({ title, children }) => {
  return (
    <section id={`panel-${title}`} className="panel">
      {children}
    </section>
  )
}

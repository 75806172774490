import React from 'react'

export function siteAddress(data) {
  return data.address || null
}

export function siteCaption(data) {
  const address = data.address,
        neighborhood = data.neighborhood,
        region = data.region && data.region.name

  let caption = ``

  if(address) caption += address
  if(neighborhood) caption += `${address ? ', ' : ''}${neighborhood}`

  if(address && neighborhood) return caption

  if(region) caption += `${address || neighborhood ? ', ' : ''}${region}`

  return caption
}

export function siteName(data) {
  return data.neighborhood
    ? data.neighborhood
    : data.region && data.region.name
      ? data.region.name
      : null
}

export function siteType(data) {
  if(!data
    || !data.type
    || !data.type.document
    || !data.type.document.data
    || !data.type.document.data.name
  ) return null
  return data.type.document.data.name
}

export function siteAcreage(data) {
  if(!data
    || !data.acreage
  ) return null
  return `${data.acreage} acre${data.acreage !== 1 ? 's' : ''}`
}

export function collaboratorName(data) {
  if(!data) return null
  return data.name
}

export function collaboratorRole(data) {
  if(!data
    || !data.role
    || !data.role.document
    || !data.role.document.data
    || !data.role.document.data.name
  ) return null
  return data.role.document.data.name
}

export function collaboratorContact(data) {
  if(!data) return null

  const href = data.email
    ? `mailto:${data.email}`
    : data.url && data.url.url
      ? data.url.url
      : null

  const props = {
    className: `contact`,
    href: href,
    children: data.email
      ? data.email
      : data.url && data.url.url
        ? data.url.url.replace(/^https?:\/\/(www\.)?|\/$/g,``)
        : null
  }

  if(href) return <a {...props} />
  return null
}

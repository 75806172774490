import React from 'react'
import {
  collaboratorName,
  collaboratorRole,
  collaboratorContact
} from '../utils'
import '../styles/collaborator.css'

export default ({ collaborator }) => {
  const { id, data } = collaborator,
    role = collaboratorRole(data),
    name = collaboratorName(data),
    contact = collaboratorContact(data)

  return (
    <p className="collaborator">
      <span className="role">{role}</span>
      <span className="name">{name}</span>
      {contact}
    </p>
  )
}

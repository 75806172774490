import React from 'react'
import Site from './site'
import { siteName } from '../utils'
import '../styles/region.css'

export default ({ region }) => {
  const { data: { name, order }, id } = region

  return (
    <div className="region" data-name={name}>
      <h3 className="desktop-hide">{name}</h3>
      <div className="sites">
        {order.filter(({ site }) => site.document)
          .map(({ site: { document: site }}) => site
            ? <Site key={site.id} site={site} />
            : null
          )
        }
      </div>
    </div>
  )
}
